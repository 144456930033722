import React, { Component } from "react";
import DataTable from 'react-data-table-component';
import LoadingOverlay from "react-loading-overlay";
import RemoveUserButton from './removeUserButton';


class UsersTable extends Component {
  columns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Email',
      selector: 'displayed_email',
      sortable: true,
      center: true,
    },
    {
      name: 'Role',
      selector: 'role',
      sortable: true,
      center: true,
      width: '8em'
    },
    {
      name: 'Date Invited',
      selector: 'invitationDate',
      sortable: true,
      center: true
    },
    {
      name: 'Last Login',
      selector: 'lastLoginDate',
      sortable: true,
      center: true
    },
    {
      cell: (user) => <RemoveUserButton user={user} refreshUsersData={this.props.refreshUsersData} />,
      // allowOverflow: true,
      button: true,
      center: true
    }
  ];

  render() {
    return (
      <LoadingOverlay active={this.props.loading} spinner>
        <DataTable
          columns={this.columns}
          data={this.props.users}
          pagination={true}
          paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          paginationPerPage={5}
          noHeader={true}
          sortFunction={this.props.handleSort}
          className="table table-bordered table-striped"
          style={{ width: 'calc(100vw - 30px)' }}
        />
      </LoadingOverlay>
    )
  }
};

export default UsersTable;